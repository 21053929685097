import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { useAuth } from '../../context/AuthContext';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const firstName = user?.name?.split(' ')[0] || '';

  return (
    <Layout>
      <div className="dashboard">
        <h1>Welcome, {firstName}!</h1>
        <div className="tools-grid">
          <Link to="/salesmap" className="tool-card">
            <h2>Click to view PC Map</h2>
            <p>Check if your seller lead is in-area!</p>
          </Link>
          {/* Add more tool cards here as needed */}
        </div>
        <style>
          {`
          .dashboard {
            padding: 2rem;
            max-width: 1200px;
            margin: 0 auto;
            margin-top: 2rem;
          }

          h1 {
            font-size: 2rem;
            color: #333;
            margin-bottom: 2rem;
          }

          .tools-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 2rem;
            margin-top: 2rem;
          }

          .tool-card {
            background: white;
            border-radius: 8px;
            padding: 1.5rem;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: all 0.2s;
            border: 1px solid #eee;
            text-decoration: none;
            color: inherit;
          }

          .tool-card:hover {
            transform: translateY(-4px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border-color: #ddd;
          }

          .tool-card h2 {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
            color: #333;
          }

          .tool-card p {
            color: #666;
            line-height: 1.5;
          }
          `}
        </style>
      </div>
    </Layout>
  );
};

export default Dashboard;
