import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const styles = {
  nav: {
    height: '60px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    '@media (max-width: 768px)': {
      height: '50px',
      padding: '0 0.5rem'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '1200px',
    margin: '0 auto',
    width: '100%',
    height: '100%'
  },
  brand: {
    fontSize: '1.5rem',
    fontWeight: 'bold' as const,
    color: '#333',
    textDecoration: 'none',
    '@media (max-width: 768px)': {
      fontSize: '1.25rem'
    }
  },
  navLinks: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    marginLeft: '3rem',
    '@media (max-width: 768px)': {
      gap: '1rem',
      marginLeft: '1rem'
    }
  },
  navLink: {
    color: '#666',
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: '500' as const,
    transition: 'color 0.2s ease',
    ':hover': {
      color: '#333'
    }
  },
  activeLink: {
    color: '#333',
    fontWeight: 'bold' as const
  },
  userSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem'
  },
  button: {
    padding: '0.5rem 1rem',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.875rem',
    transition: 'background-color 0.2s ease',
    ':hover': {
      backgroundColor: '#0056b3'
    }
  },
  logoutButton: {
    padding: '0.5rem 1rem',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.875rem',
    transition: 'background-color 0.2s ease',
    ':hover': {
      backgroundColor: '#c82333'
    }
  },
  avatar: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    objectFit: 'cover' as const,
    border: '2px solid #eee'
  },
  userName: {
    fontSize: '0.875rem',
    color: '#333',
    fontWeight: '500' as const
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem'
  },
  userPicture: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    objectFit: 'cover' as const,
    border: '2px solid #eee'
  }
};

const Navigation: React.FC = () => {
  const { isAuthenticated, user, logout } = useAuth();
  const currentPath = window.location.pathname;

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Navigation logout error:', error);
    }
  };

  return (
    <nav style={styles.nav}>
      <div style={styles.content}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/" style={styles.brand}>
            PopTools
          </Link>
          <div style={styles.navLinks}>
            <Link
              to="/"
              style={{
                ...styles.navLink,
                ...(true ? styles.activeLink : {})
              }}
            >
              Home
            </Link>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {user && (
            <>
              <div style={styles.userInfo}>
                <img src={user.picture} alt={user.name} style={styles.userPicture} />
                <span style={styles.userName}>{user.name}</span>
              </div>
              <button
                onClick={handleLogout}
                style={styles.logoutButton}
              >
                Logout
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
