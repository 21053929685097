import React from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import { Login } from '../Login';
import { useAuth } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';

export const AdminLoginPage: React.FC = () => {
  const { user } = useAuth();

  // Redirect to admin page if already authenticated and is admin
  if (user?.isAdmin) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography component="h1" variant="h5" gutterBottom>
            PopTools Admin Login
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 3 }}>
            Please sign in with your admin account
          </Typography>
          <Login isAdmin={true} redirectPath="/admin" />
        </Paper>
      </Box>
    </Container>
  );
};
