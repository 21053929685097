import React from 'react';
import Layout from '../../components/Layout/Layout';
import { useAuth } from '../../context/AuthContext';
import GoogleMap from '../../components/Maps/GoogleMap';

const SalesMap: React.FC = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="sales-map">
        <h1>PC Locations</h1>
        <div className="map-container">
          <GoogleMap />
        </div>
        <style>{`
          .sales-map {
            padding: 1rem;
            padding-top: 80px;
          }

          .sales-map h1 {
            margin: 0 0 1rem;
            font-size: 1.75rem;
            font-weight: 600;
            color: #333;
          }

          .map-container {
            width: 100%;
            height: 60vh;
            min-height: 400px;
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          }

          @media (max-width: 768px) {
            .sales-map {
              padding: 0.5rem;
              padding-top: 60px;
            }

            .sales-map h1 {
              font-size: 1.25rem;
              margin: 0 0 0.5rem;
              padding: 0 0.5rem;
            }

            .map-container {
              height: calc(100vh - 100px);
              min-height: 400px;
              border-radius: 4px;
            }
          }
        `}</style>
      </div>
    </Layout>
  );
};

export default SalesMap;
