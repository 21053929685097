import React, { useState, useEffect } from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate
} from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider, useAuth } from './context/AuthContext';
import { AdminPage } from './components/Admin/AdminPage';
import { AdminLoginPage } from './components/Admin/AdminLoginPage';
import Dashboard from './pages/Dashboard/Dashboard';
import SalesMap from './pages/SalesMap/SalesMap';
import ProtectedRoute from './components/ProtectedRoute';
import { Login } from './components/Login';
import Layout from './components/Layout/Layout';
import { API_URL } from './config';
import { ScraperControl } from './components/Admin/ScraperControl'; // Added import statement

const AppRoutes: React.FC = () => {
  const { user, isLoading } = useAuth();

  // Show nothing while checking authentication status
  if (isLoading) {
    return null;
  }

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/login"
          element={user ? <Navigate to="/dashboard" replace /> : <Login />}
        />
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<Dashboard />} />}
        />
        <Route
          path="/salesmap"
          element={<ProtectedRoute element={<SalesMap />} />}
        />
        <Route 
          path="/admin/login" 
          element={<AdminLoginPage />} 
        />
        <Route 
          path="/admin" 
          element={
            <ProtectedRoute 
              element={<AdminPage />} 
              requireAdmin={true}
              fallback="/admin/login"
            />
          } 
        />
        <Route 
          path="/admin/scraper" 
          element={
            <ProtectedRoute 
              element={<ScraperControl />} 
              requireAdmin={true}
              fallback="/admin/login"
            />
          } 
        />
        {/* Catch all route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
};

const App: React.FC = () => {
  const [clientId, setClientId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchClientId = async () => {
      try {
        const response = await fetch(`${API_URL}/auth/GOOGLE_CLIENT_ID`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch client ID');
        }
        
        const data = await response.json();
        if (!data.clientId) {
          throw new Error('No client ID received');
        }
        
        setClientId(data.clientId);
      } catch (error) {
        console.error('Error fetching client ID:', error);
        setError('Failed to initialize authentication');
      }
    };

    fetchClientId();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!clientId) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthProvider>
        <Router>
          <AppRoutes />
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
