import React from 'react';
import { Box, Container, Paper, Typography, Button, Grid } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import BuildIcon from '@mui/icons-material/Build';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CodeIcon from '@mui/icons-material/Code';

interface AdminCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const AdminCard: React.FC<AdminCardProps> = ({ title, description, icon, onClick }) => (
  <Paper
    sx={{
      p: 3,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'scale(1.02)',
      },
    }}
    onClick={onClick}
  >
    <Box sx={{ mb: 2, color: 'primary.main' }}>{icon}</Box>
    <Typography variant="h6" component="h2" gutterBottom align="center">
      {title}
    </Typography>
    <Typography color="text.secondary" align="center">
      {description}
    </Typography>
  </Paper>
);

export const AdminPage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const adminTools = [
    {
      title: 'Scraper Control',
      description: 'Manage and monitor web scraping operations',
      icon: <CodeIcon sx={{ fontSize: 40 }} />,
      onClick: () => navigate('/admin/scraper')
    },
    {
      title: 'Dashboard',
      description: 'View system statistics and performance metrics',
      icon: <DashboardIcon sx={{ fontSize: 40 }} />,
      onClick: () => navigate('/dashboard')
    },
    {
      title: 'System Tools',
      description: 'Access system configuration and maintenance tools',
      icon: <BuildIcon sx={{ fontSize: 40 }} />,
      onClick: () => navigate('/admin/tools')
    }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Admin Dashboard
        </Typography>
        <Typography color="text.secondary" sx={{ mb: 4 }}>
          Welcome back, {user.name}. Select a tool to get started.
        </Typography>
        
        <Grid container spacing={3}>
          {adminTools.map((tool, index) => (
            <Grid item xs={12} md={4} key={index}>
              <AdminCard {...tool} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
