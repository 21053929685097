import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Card, 
  CardContent, 
  Typography, 
  TextField, 
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';

const StatusCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const ControlButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
}));

interface ScraperError {
  timestamp: Date;
  error: string;
}

interface ScraperStatus {
  isRunning: boolean;
  lastRunTime: Date | null;
  nextRunTime: Date | null;
  lastRunResult: {
    success: boolean;
    message: string;
    recordCount?: number;
    error?: string;
  } | null;
  cronInterval: number | null;
  errorLog: ScraperError[];
}

export const ScraperControl: React.FC = () => {
  const [status, setStatus] = useState<ScraperStatus>({
    isRunning: false,
    lastRunTime: null,
    nextRunTime: null,
    lastRunResult: null,
    cronInterval: null,
    errorLog: []
  });
  const [minutes, setMinutes] = useState<string>('10');
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const fetchStatus = async () => {
    try {
      const response = await fetch('/api/admin/scraper/status');
      if (!response.ok) throw new Error('Failed to fetch status');
      const data = await response.json();
      setStatus({
        ...data,
        lastRunTime: data.lastRunTime ? new Date(data.lastRunTime) : null,
        nextRunTime: data.nextRunTime ? new Date(data.nextRunTime) : null,
        errorLog: data.errorLog.map((error: any) => ({
          ...error,
          timestamp: new Date(error.timestamp)
        }))
      });
    } catch (error) {
      console.error('Failed to fetch status:', error);
      setMessage('Failed to fetch status');
    }
  };

  useEffect(() => {
    fetchStatus();
    const interval = setInterval(fetchStatus, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleRunOnce = async () => {
    setLoading(true);
    setMessage('');
    try {
      const response = await fetch('/api/admin/scraper/run', {
        method: 'POST',
      });
      if (!response.ok) throw new Error('Failed to run scraper');
      const data = await response.json();
      setMessage(data.message);
      setStatus(data.status);
    } catch (error) {
      setMessage('Failed to run scraper');
    } finally {
      setLoading(false);
    }
  };

  const handleStartCron = async () => {
    try {
      const response = await fetch('/api/admin/scraper/start', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ minutes: parseInt(minutes) }),
      });
      if (!response.ok) throw new Error('Failed to start cron job');
      const data = await response.json();
      setMessage(data.message);
      setStatus(data.status);
    } catch (error) {
      setMessage('Failed to start cron job');
    }
  };

  const handleStopCron = async () => {
    try {
      const response = await fetch('/api/admin/scraper/stop', {
        method: 'POST',
      });
      if (!response.ok) throw new Error('Failed to stop cron job');
      const data = await response.json();
      setMessage(data.message);
      setStatus(data.status);
    } catch (error) {
      setMessage('Failed to stop cron job');
    }
  };

  const handleClearErrors = async () => {
    try {
      const response = await fetch('/api/admin/scraper/clear-errors', {
        method: 'POST',
      });
      if (!response.ok) throw new Error('Failed to clear errors');
      const data = await response.json();
      setMessage(data.message);
      setStatus(data.status);
    } catch (error) {
      setMessage('Failed to clear errors');
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        DH Scraper Control Panel
      </Typography>

      <StatusCard>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Status
          </Typography>
          <Box sx={{ display: 'grid', gap: 1 }}>
            <Typography>
              Status: {status.isRunning ? 'Running' : 'Stopped'}
            </Typography>
            {status.lastRunTime && (
              <Typography>
                Last Run: {format(status.lastRunTime, 'PPpp')}
              </Typography>
            )}
            {status.nextRunTime && (
              <Typography>
                Next Run: {format(status.nextRunTime, 'PPpp')}
              </Typography>
            )}
            {status.lastRunResult && (
              <Typography color={status.lastRunResult.success ? 'success.main' : 'error.main'}>
                Last Result: {status.lastRunResult.message}
              </Typography>
            )}
          </Box>
        </CardContent>
      </StatusCard>

      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
              <ControlButton
                variant="contained"
                color="primary"
                onClick={handleRunOnce}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Run Once'}
              </ControlButton>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TextField
                label="Minutes between runs"
                type="number"
                value={minutes}
                onChange={(e) => setMinutes(e.target.value)}
                disabled={status.isRunning}
                size="small"
              />
              <ControlButton
                variant="contained"
                color={status.isRunning ? 'error' : 'success'}
                onClick={status.isRunning ? handleStopCron : handleStartCron}
              >
                {status.isRunning ? 'Stop Cron' : 'Start Cron'}
              </ControlButton>
            </Box>

            {message && (
              <Typography 
                color={message.includes('Failed') ? 'error' : 'success'}
                sx={{ mt: 2 }}
              >
                {message}
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>

      {status.errorLog.length > 0 && (
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Error Log</Typography>
              <Tooltip title="Clear error log">
                <IconButton onClick={handleClearErrors}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Time</TableCell>
                    <TableCell>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {status.errorLog.map((error, index) => (
                    <TableRow key={index}>
                      <TableCell>{format(error.timestamp, 'PPpp')}</TableCell>
                      <TableCell>{error.error}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};
