import React, { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { API_URL } from '../config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import './Login.css';

// Add FedCM types
declare global {
  interface Window {
    PublicKeyCredential: {
      isConditionalMediationAvailable?: () => Promise<boolean>;
      prototype: PublicKeyCredential;
      new (): PublicKeyCredential;
    };
  }
}

interface LoginProps {
  onLoginSuccess?: (userData: any) => void;
  isAdmin?: boolean;
  redirectPath?: string;
}

// Lazy load the GoogleLogin component
const GoogleLogin = lazy(() => import('@react-oauth/google').then(module => ({
  default: module.GoogleLogin
})));

export const Login: React.FC<LoginProps> = ({ 
  onLoginSuccess, 
  isAdmin = false,
  redirectPath = '/dashboard'
}) => {
  const { setAccessToken } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);  
  const [fedCMSupported, setFedCMSupported] = useState(true); 
  const [clientId, setClientId] = useState<string | null>(null);

  const handleGoogleResponse = useCallback(async (response: any) => {
    try {
      console.log('Google response received:', { 
        hasCredential: !!response?.credential,
        responseType: typeof response
      });

      if (!response?.credential) {
        throw new Error('Not signed in with the identity provider.');
      }

      setIsLoading(true);
      setError(null);

      // Create a new fetch request with credentials included
      const apiResponse = await window.fetch(`${API_URL}/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ 
          credential: response.credential,
          isAdmin: isAdmin // Add isAdmin flag to request
        })
      });

      console.log('API response status:', apiResponse.status);
      const contentType = apiResponse.headers.get('content-type');
      console.log('API response content type:', contentType);

      if (!apiResponse.ok) {
        const errorData = await apiResponse.json().catch(() => ({ 
          message: isAdmin ? 'Admin authentication failed' : 'Authentication failed'
        }));
        throw new Error(errorData.message || 'Authentication failed');
      }

      const userData = await apiResponse.json();
      console.log('API response data:', {
        hasAccessToken: !!userData.accessToken,
        hasUser: !!userData.user,
        isAdmin: !!userData.user?.isAdmin
      });
      
      if (!userData.accessToken) {
        throw new Error('No access token received');
      }

      // For admin login, verify admin status
      if (isAdmin && !userData.user?.isAdmin) {
        throw new Error('This account does not have admin privileges');
      }

      // Set access token in auth context
      setAccessToken(userData.accessToken);
      sessionStorage.setItem('access_token', userData.accessToken);
      
      // Use React Router navigation
      const targetPath = isAdmin ? '/admin' : redirectPath;
      console.log('Redirecting to:', targetPath);
      navigate(targetPath, { replace: true });

      // Call onLoginSuccess callback if provided
      if (onLoginSuccess) {
        onLoginSuccess(userData);
      }

    } catch (error) {
      console.error('Login error:', error);
      setError(error instanceof Error ? error.message : 'Login failed');
      setAccessToken(null);
      sessionStorage.removeItem('access_token');
    } finally {
      setIsLoading(false);
    }
  }, [isAdmin, onLoginSuccess, setAccessToken, redirectPath, navigate]);

  useEffect(() => {
    const checkFedCMSupport = async () => {
      if (!window.PublicKeyCredential?.isConditionalMediationAvailable) {
        setFedCMSupported(true);
        return;
      }
      try {
        const isCMA = await window.PublicKeyCredential.isConditionalMediationAvailable();
        setFedCMSupported(isCMA);
      } catch (error) {
        setFedCMSupported(true);
        console.error('FedCM support check failed:', error);
      }
    };

    // Run FedCM check in parallel with other initialization
    checkFedCMSupport();
  }, []);

  useEffect(() => {
    const initializeFedCM = async () => {
      // Check if we're in a logout flow
      const urlParams = new URLSearchParams(window.location.search);
      const isLogout = urlParams.get('logout') === 'true';
      
      if (isLogout) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);

        // Skip FedCM if not supported
        if (!fedCMSupported) {
          setIsLoading(false);
          return;
        }

        const options: FedCMIdentityCredentialRequestOptions = {
          identity: {
            providers: [{
              configURL: `${API_URL}/fedcm/.well-known/fedcm.json`,
              clientId: 'google',
            }]
          },
          mediation: 'optional'
        };

        // Set a timeout to prevent long delays
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('FedCM timeout')), 2000);
        });

        try {
          const credential = await Promise.race([
            navigator.credentials.get(options),
            timeoutPromise
          ]);
          
          if (credential) {
            handleGoogleResponse({ credential });
          }
        } catch (error) {
          // Silently fail and continue with regular sign-in
          console.debug('FedCM not available, using regular sign-in');
        }
      } finally {
        setIsLoading(false);
      }
    };

    initializeFedCM();
  }, [fedCMSupported, handleGoogleResponse]);

  // Load client ID only once on mount
  useEffect(() => {
    const cachedClientId = sessionStorage.getItem('google_client_id');
    if (cachedClientId) {
      setClientId(cachedClientId);
      return;
    }

    fetch(`${API_URL}/api/auth/GOOGLE_CLIENT_ID`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    .then(async response => {
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch client ID');
      }
      return response.json();
    })
    .then(data => {
      if (data.clientId) {
        sessionStorage.setItem('google_client_id', data.clientId);
        setClientId(data.clientId);
      } else {
        throw new Error('Invalid client ID response');
      }
    })
    .catch(error => {
      console.error('Failed to fetch Google client ID:', error);
      setError('Failed to initialize Google Sign-In');
    });
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      (window as any).handleGoogleResponse = handleGoogleResponse;
    }
    return () => {
      if (typeof window !== 'undefined') {
        delete (window as any).handleGoogleResponse;
      }
    };
  }, [handleGoogleResponse]);

  return (
    <div className="login-container">
      {isLoading && <div className="loading">Loading...</div>}
      {error && <div className="error">{error}</div>}
      {!isLoading && clientId && (
        <GoogleOAuthProvider clientId={clientId}>
          <Suspense fallback={<div>Loading...</div>}>
            <GoogleLogin
              onSuccess={handleGoogleResponse}
              onError={() => {
                console.error('Login Failed');
                setError('Google Sign-In failed');
              }}
              useOneTap={false}
            />
          </Suspense>
        </GoogleOAuthProvider>
      )}
    </div>
  );
};