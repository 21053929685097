// Utility to handle logging configuration
const originalFetch = window.fetch;

// Override fetch to prevent logging of image and map tile requests
window.fetch = async function(input: RequestInfo | URL, init?: RequestInit) {
    const url = input.toString();
    const isImageRequest = url.match(/\.(jpg|jpeg|png|gif|webp)$/i);
    const isMapTileRequest = url.includes('maps.googleapis.com/maps/vt') || 
                           url.includes('maps.googleapis.com/maps/api/js') ||
                           url.includes('maps.gstatic.com');

    // Only log non-image and non-map-tile requests
    if (!isImageRequest && !isMapTileRequest) {
        console.log('Fetching:', url);
    }

    return originalFetch.apply(this, [input, init]);
};

// Configure console to filter out specific types of logs
const originalConsoleLog = console.log;
console.log = function(...args: any[]) {
    const firstArg = args[0]?.toString() || '';
    
    // Skip logging for specific patterns
    const skipPatterns = [
        /\.(jpg|jpeg|png|gif|webp)$/i,
        /maps\.googleapis\.com/,
        /maps\.gstatic\.com/,
        /fonts\.googleapis\.com/,
        /google-analytics/,
    ];

    if (!skipPatterns.some(pattern => firstArg.match(pattern))) {
        originalConsoleLog.apply(console, args);
    }
};

const noop = (message: string, ...args: any[]): void => {
  // In production, we might want to send logs to a logging service
  if (process.env.NODE_ENV === 'development') {
    console.log(message, ...args);
  }
};

export const initializeLogging = () => {
    // Additional logging configuration can be added here
    if (process.env.NODE_ENV === 'production') {
        console.log = noop;  // Implement proper logging function instead of empty function
    }
};
