import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/loading.css';

interface Props {
  element: React.ReactElement;
  requireAdmin?: boolean;
  fallback?: string;
}

const ProtectedRoute: React.FC<Props> = ({ 
  element, 
  requireAdmin = false,
  fallback = '/login'
}) => {
  const { user, isLoading } = useAuth();

  // Show nothing while checking authentication
  if (isLoading) {
    return null;
  }

  // Not authenticated
  if (!user) {
    return <Navigate to={fallback} replace />;
  }

  // Check admin access if required
  if (requireAdmin && !user.isAdmin) {
    return <Navigate to={fallback} replace />;
  }

  // Authenticated and has required permissions
  return element;
};

export default ProtectedRoute;
