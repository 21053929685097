import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { API_URL } from '../../config';
import './GoogleMap.css';

// Interface definitions
interface PC {
  pc_id: string;
  name: string;
  photo_url?: string;
  contact?: { phone: string };
  phone: string;
  experience?: {
    time_at_pop: string;
    categories: string[];
  };
  metrics?: {
    listings_count: number;
    daily_effort_minutes: number;
    daily_effort_change: string;
    deals_closed: number;
    deals_in_closing: number;
    buyer_conversion_rate: number;
    local_listing_conversion: number;
  };
  location: {
    type: string;
    coordinates: [number, number];
  };
  territory: {
    radius_miles: number;
    field_rep_radius?: number;
    zip_code?: string;
  };
  status: {
    is_online: boolean;
    last_active: Date;
    flag_color: string;
  };
  licenses?: string[];
  acceptance?: {
    rate: number;
    accepted: number;
    total: number;
  };
}

interface MapProps {
  center: google.maps.LatLngLiteral;
  zoom: number;
  pcs?: PC[];
  mapId?: string;
}

interface SearchResult {
  lat: number;
  lng: number;
  formatted_address: string;
}

interface PlaceResult extends google.maps.places.Place {
  location: google.maps.LatLng;
  displayName: string | null | undefined;
}

interface MarkerInfo {
  marker: google.maps.marker.AdvancedMarkerElement | null;
  circle: google.maps.Circle | null;
  infoWindow: google.maps.InfoWindow | null;
}

declare global {
  interface Window {
    placesService: any;
  }
}

// SearchBox Component
const SearchBox: React.FC<{ onSearch: (result: SearchResult) => void; isLoading: boolean }> = ({ onSearch, isLoading }) => {
  const [isSearching, setIsSearching] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearch = async (query: string) => {
    if (!query) return;
    
    setIsSearching(true);
    
    try {
      // First try Places API for more accurate results
      const { Place } = await google.maps.importLibrary("places") as google.maps.PlacesLibrary;
      const geocodingRequest = {
        address: query,
        componentRestrictions: {
          country: 'US'
        },
        // If it's a 5-digit number, treat it as a ZIP code
        ...((/^\d{5}$/.test(query)) && {
          address: query + ", United States",
          region: 'us'
        })
      };

      const { Geocoder } = await google.maps.importLibrary("geocoding") as google.maps.GeocodingLibrary;
      const geocoder = new Geocoder();
      const response = await geocoder.geocode(geocodingRequest);
      
      if (response.results && response.results.length > 0) {
        const place = response.results[0];
        const location = place.geometry.location;
        
        const searchResult: SearchResult = {
          lat: location.lat(),
          lng: location.lng(),
          formatted_address: place.formatted_address || query
        };
        
        onSearch(searchResult);
        
        // Clear the input after successful search
        if (searchInputRef.current) {
          searchInputRef.current.value = '';
        }
      } else {
        
      }
    } catch (error) {
      console.error('Error searching:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchInputRef.current) {
      handleSearch(searchInputRef.current.value);
    }
  };

  return (
    <div className="search-box">
      <div className="search-input-container">
        <input
          ref={searchInputRef}
          type="text"
          className="search-input"
          placeholder="Search locations..."
          onKeyPress={handleKeyPress}
        />
        {isSearching && (
          <div className="search-spinner">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    </div>
  );
};

// Error Component
const ErrorComponent: React.FC<{ error: string }> = ({ error }) => (
  <div className="error-container">
    <h1>Error</h1>
    <p>{error}</p>
  </div>
);

// Map Component
const MapComponent: React.FC<MapProps> = ({ center, zoom, pcs = [], mapId }) => {
  const mapDivRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const pcMarkersRef = useRef<MarkerInfo[]>([]);
  const searchMarkerRef = useRef<{
    marker: google.maps.marker.AdvancedMarkerElement | null;
  } | null>(null);
  const [activeInfoWindow, setActiveInfoWindow] = useState<google.maps.InfoWindow | null>(null);

  const createMarkerContent = useCallback((isGreen: boolean) => {
    const markerContent = document.createElement('div');
    markerContent.className = 'custom-marker';
    const flagColor = isGreen ? 'green' : 'gray';
    markerContent.innerHTML = `
      <div class="marker-dot ${flagColor}-dot"></div>
    `;
    return markerContent;
  }, []);

  const createInfoWindowContent = useCallback((pc: PC) => {
    const formatNumber = (num: number) => new Intl.NumberFormat().format(num);
    const formatPercent = (num: number) => (num * 100).toFixed(2) + '%';
    const formatPhone = (phone: string) => {
      return phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    };
    
    return `
      <div class="info-window">
        <div class="info-window-header">
          <div class="profile-section">
            ${pc.photo_url ? 
              `<img src="${pc.photo_url}" alt="${pc.name}" class="profile-photo" />` : 
              `<div class="profile-photo-placeholder">
                <svg viewBox="0 0 24 24" width="32" height="32">
                  <path fill="currentColor" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
                </svg>
              </div>`
            }
            <div class="header-content">
              <h2>${pc.name}</h2>
              ${pc.contact?.phone ? `
                <a href="tel:${pc.contact.phone}" class="phone-link">
                  ${formatPhone(pc.contact.phone)}
                </a>
              ` : ''}
            </div>
          </div>
        </div>
        <div class="info-window-body">
          <div class="info-section">
            <div class="info-row">
              <span class="label">Time at POP:</span>
              <span class="value">${pc.experience?.time_at_pop} ${pc.experience?.categories ? `(${pc.experience.categories.join(' & ')})` : ''}</span>
            </div>
            <div class="info-row">
              <span class="label">Listings:</span>
              <span class="value highlight">${formatNumber(pc.metrics?.listings_count || 0)} 
                ${pc.metrics?.listings_count ? '<a href="#" class="show-link">Show</a>' : ''}
              </span>
            </div>
            <div class="info-row">
              <span class="label">Daily Effort:</span>
              <span class="value">
                ${formatNumber(pc.metrics?.daily_effort_minutes || 0)} mins
                ${pc.metrics?.daily_effort_change ? 
                  `<span class="change-indicator">(${pc.metrics.daily_effort_change})</span>` : 
                  ''}
              </span>
            </div>
            <div class="info-row">
              <span class="label">Deals:</span>
              <span class="value">
                ${formatNumber(pc.metrics?.deals_closed || 0)} closed
                ${pc.metrics?.deals_in_closing ? 
                  `<span class="deals-closing">(${pc.metrics.deals_in_closing} in closing)</span>` : 
                  ''}
              </span>
            </div>
            <div class="info-row">
              <span class="label">Buyer Conversion:</span>
              <span class="value">${formatPercent(pc.metrics?.buyer_conversion_rate || 0)}</span>
            </div>
            <div class="info-row">
              <span class="label">Local Listing Conv:</span>
              <span class="value">${(pc.metrics?.local_listing_conversion || 0).toFixed(2)}%</span>
            </div>
            <div class="info-row territory">
              <span class="value territory-info">
                ${pc.territory?.radius_miles || 20} mi. around ${pc.territory?.zip_code || ''} 
                ${pc.territory?.field_rep_radius ? `(${pc.territory.field_rep_radius} mi. as Field Rep)` : ''}
              </span>
            </div>
            ${pc.acceptance ? `
              <div class="info-row acceptance">
                <span class="value">
                  ${(pc.acceptance.rate * 100).toFixed(0)}% accepted (${pc.acceptance.accepted} of ${pc.acceptance.total})
                </span>
              </div>
            ` : ''}
          </div>
        </div>
      </div>
    `;
  }, []);

  const handleSearch = async (result: SearchResult) => {
    if (!mapRef.current) {
      console.error('MapComponent: Map reference is not available');
      return;
    }

    try {
      const searchLocation = { lat: result.lat, lng: result.lng };
      
      // Pan and zoom to the location
      mapRef.current.panTo(searchLocation);
      mapRef.current.setZoom(8.5);

      // Clear previous search marker if it exists
      if (searchMarkerRef.current) {
        if (searchMarkerRef.current.marker) searchMarkerRef.current.marker.map = null;
      }

      // Create new marker
      const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
      
      const pin = new PinElement({
        background: "#FF4444",
        borderColor: "#ffffff",
        glyphColor: "#ffffff",
        scale: 0.9
      });

      // Create wrapper for animation
      const wrapper = document.createElement('div');
      wrapper.className = 'search-pin';
      wrapper.appendChild(pin.element);

      // Create and add the marker
      const marker = new AdvancedMarkerElement({
        map: mapRef.current,
        position: searchLocation,
        title: result.formatted_address,
        content: wrapper,
        gmpClickable: false
      });
      
      searchMarkerRef.current = { marker };
      
    } catch (error) {
      console.error('Error handling search:', error);
    }
  };

  useEffect(() => {
    const initMap = async () => {
      if (!mapDivRef.current) return;

      try {
        const map = new window.google.maps.Map(mapDivRef.current!, {
          center,
          zoom,
          clickableIcons: false,
          fullscreenControl: true,
          mapTypeControl: true,
          mapId
        });
        
        mapRef.current = map;
        if (process.env.NODE_ENV === 'development') {
          console.log('Map initialized with Map ID:', mapId);
        }
      } catch (error) {
        console.error('Error initializing map:', error);
      }
    };

    if (mapDivRef.current) {
      initMap();
    }
  }, [center, zoom, mapId]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map || !pcs.length) {
      return;
    }

    const setupMarkers = async () => {
      try {
        // Clear existing PC markers only if the number of markers has changed
        if (pcMarkersRef.current.length !== pcs.length) {
          pcMarkersRef.current.forEach(markerInfo => {
            if (markerInfo.marker) markerInfo.marker.map = null;
            if (markerInfo.circle) markerInfo.circle.setMap(null);
            if (markerInfo.infoWindow) markerInfo.infoWindow.close();
          });
          pcMarkersRef.current = [];
        } else {
          // If we have the same number of markers, just update their positions
          return;
        }

        const newMarkers = pcs.map(pc => {
          const position = {
            lat: pc.location.coordinates[1],
            lng: pc.location.coordinates[0]
          };

          const isGreen = pc.status.flag_color.toLowerCase().includes('green');
          const marker = new window.google.maps.marker.AdvancedMarkerElement({
            map,
            position,
            title: pc.name,
            content: createMarkerContent(isGreen),
            gmpClickable: true,
            gmpDraggable: false
          });

          const circle = new google.maps.Circle({
            map,
            center: position,
            radius: (pc.territory?.radius_miles || 10) * 1609.34, // Convert miles to meters
            fillColor: isGreen ? '#4CAF50' : '#808080',
            fillOpacity: isGreen ? 0.3 : 0,
            strokeColor: isGreen ? '#4CAF50' : '#808080',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            visible: true,
            clickable: false
          });

          // Create info window with rich content
          const infoWindow = new google.maps.InfoWindow({
            content: createInfoWindowContent(pc),
            maxWidth: 300,
            pixelOffset: new google.maps.Size(0, -30)
          });

          // Add click event listener to marker
          marker.addEventListener('gmp-click', () => {
            // Close any previously opened info window
            if (activeInfoWindow && activeInfoWindow !== infoWindow) {
              activeInfoWindow.close();
            }

            // Only open if it's not already open
            if (activeInfoWindow !== infoWindow) {
              infoWindow.open({
                map,
                anchor: marker
              });
              setActiveInfoWindow(infoWindow);
            }
          });

          // Add close listener to info window
          infoWindow.addListener('closeclick', () => {
            setActiveInfoWindow(null);
          });

          return { marker, circle, infoWindow };
        });

        pcMarkersRef.current = newMarkers;
      } catch (error) {
        console.error('Error creating markers:', error);
      }
    };

    setupMarkers();
  }, [pcs, createInfoWindowContent, createMarkerContent]);

  useEffect(() => {
    return () => {
      // Cleanup function
      if (activeInfoWindow) {
        activeInfoWindow.close();
      }
      if (searchMarkerRef.current) {
        if (searchMarkerRef.current.marker) searchMarkerRef.current.marker.map = null;
      }
    };
  }, [activeInfoWindow]);

  return (
    <div className="map-container">
      <SearchBox onSearch={handleSearch} isLoading={false} />
      <div ref={mapDivRef} id="map" style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

// Main Google Map Component
const GoogleMap: React.FC = () => {
  const [mapsApiKey, setMapsApiKey] = useState<string | null>(null);
  const [mapId, setMapId] = useState<string | null>(null);
  const [pcs, setPcs] = useState<PC[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchApiKeyAndPCs = async () => {
      try {
        // Fetch Maps API key
        const response = await fetch(`${API_URL}/maps/api-key`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch Maps API key');
        }
        
        const { apiKey } = await response.json();
        setMapsApiKey(apiKey);

        // Fetch Map ID
        const mapIdResponse = await fetch(`${API_URL}/maps/map-id`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        
        if (!mapIdResponse.ok) {
          throw new Error('Failed to fetch Map ID');
        }
        
        const { mapId: id } = await mapIdResponse.json();
        if (!id) {
          throw new Error('No Map ID in response');
        }
        setMapId(id);

        // Fetch PCs
        const pcsResponse = await fetch(`${API_URL}/pcs`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json'
          }
        });

        if (!pcsResponse.ok) {
          throw new Error('Failed to fetch PC data');
        }

        const pcsData = await pcsResponse.json();
        setPcs(pcsData);
      } catch (err) {
        console.error('Error:', err);
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchApiKeyAndPCs();
  }, []);

  if (!mapsApiKey) {
    return <ErrorComponent error="Maps API key not found" />;
  }

  if (!mapId) {
    return <ErrorComponent error="Map ID not found" />;
  }

  if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <div className="map-container">
      {isLoading && <div className="loading">Loading...</div>}
      <Wrapper 
        apiKey={mapsApiKey}
        version="beta"
        libraries={["marker", "places", "geocoding"]}
      >
        <MapComponent
          center={{ lat: 39.8283, lng: -98.5795 }}
          zoom={5}
          pcs={pcs}
          mapId={mapId}
        />
      </Wrapper>
    </div>
  );
};

export default GoogleMap;
